<template>
  <div
    class="type-coupon"
    :style="couponStyle"
  >
    <s-loading
      :show="loading"
      :container-cover-style="{ 'background-color': '#fff' }"
      :type="page ? 'newpage' : 'curpage'"
    />
    <AddOnItem
      v-if="couponItem"
      :drawer-config="drawerConfig"
      :language="language"
      :abt-info="abtInfo"
      :query-info="{
        ...$attrs.queryInfo,
        ...anotherInfo,
      }"
      :store-info="$attrs.storeInfo"
      :type="$attrs.type"
      :sa-info="$attrs.saInfo"
      :config="config"
      :main-code="couponItem.couponCode"
      :footer-type="footerType"
      :feeds-type="feedsType"
      :get-business-cart-foot="getBusinessCartFoot"
      :draggable="!page && !isCouponAdd"
      @update-cart="handleUpdateCart"
    > 
      <template #header>
        <transition
          name="header-animate"
        >
          <component 
            :is="headerName"
            v-if="!isFullScreen"
            :coupon-item="couponItem"
            :coupon-info="couponInfo"
            :language="language"
            :drawer-config="drawerConfig"
            :page="page"
            :style-type="addOnInfo.styleType"
            @change-coupon="onChangeCoupon"
          />
          <template v-else>
            <FullScreenHead
              :tips="couponInfo.topTip"
              :style-info="fullScreenStyle"
            >
              <template #right>
                <CouponMoreReference
                  v-if="isMultiple"
                  level
                  modal
                  :language="language"
                  :drawer-config="drawerConfig"
                  :coupon-list="couponInfo.couponInfos"
                  @click-coupon="onClickCoupon"
                />
              </template>
            </FullScreenHead>
          </template>
        </transition>
      </template>
      <template #footer>
        <BusinessCartFoot 
          v-if="isBusinessCartBottom"
          ref="BusinessCartFoot"
          type="coupon"
          scene="couponAddItemPopup"
          :promotion-id="couponItem.couponCode"
          :state="$attrs.saInfo && $attrs.saInfo.state"
          :tips="couponItem.progressTip"
          :status="addOnStatus"
          :abt-type="abtType"
          :feeds-type="feedsType"
          :theme-vars="themeVar"
          :add-item-type="addItemType"
          :config="$attrs.config"
          @checkoutClick="viewCart({ couponInfo: couponItem })"
          @cartUpdated="hanldeBusinessCartUpdated"
        >
          <template #progress>
            <ProgressBar
              transparent
              animation
              :thresholds="couponItem.thresholds"
              :style-config="barStyle"
            />
          </template>
        </BusinessCartFoot>
        <component
          :is="footerName"
          v-else
          :add-item-type="addItemType"
          :coupon-item="couponItem"
          :coupon-info="couponInfo"
          :config="config"
          :language="language"
          @back="viewCart({ couponInfo: couponItem })"
        />
      </template>
    </AddOnItem>
    <NoNetworkEmpty
      :is-empty="!couponItem"
      :language="language"
      @online-changed="handleOnlineStatus"
    />
  </div>
</template>

<script>
import { cloneDeep } from '@shein/common-function'
import { getCouponInfo } from 'public/src/pages/common/add-on/utils/fetcher.js'
import { isRangeUp, getSatisfiedValue, isProgressUp } from 'public/src/pages/common/add-on/layout/typeCoupon/utils.js'

import { EventFetchError, EventExposeAddCart, EventProgressUpdate, EventListReset, EventUpdateBusinessCart, 
  EventSetProgressPromise,
  EventViewCart
} from 'public/src/pages/common/add-on/utils/event.js'
import { mapState } from 'vuex'

import BusinessCartFoot from 'public/src/pages/common/add-on/comps/business-cart-foot/index.vue'
import NoNetworkEmpty from 'public/src/pages/cart_v2/components/offline/NoNetworkEmpty.vue'
import AddOnItem from 'public/src/pages/common/add-on/layout/AddOnItem.vue'
import CouponAddHead from './couponAdd/Head.vue'
import CouponAddFoot from './couponAdd/Foot.vue'
import NormalHead from './normal/Head.vue'
import NormalFoot from './normal/Foot.vue'
import MultipleHead from './multiple/Head.vue'
import FullScreenHead from 'public/src/pages/common/add-on/comps/base/FullScreenHead.vue'
import ProgressBar from 'public/src/pages/common/add-on/comps/base/ProgressBar.vue'

const getCouponItem = (coupons, code) => {
  return coupons?.find(v => v.couponCode == code)
}

export default {
  name: 'TypeCoupon',
  components: {
    AddOnItem,
    CouponAddHead,
    CouponAddFoot,
    NormalHead,
    NormalFoot,
    MultipleHead,
    FullScreenHead,
    CouponMoreReference: () => import(/* webpackChunkName: "add_on_item_coupon_more" */'public/src/pages/common/add-on/layout/typeCoupon/multiple/couponMore/Reference.vue'),
    ProgressBar,
    BusinessCartFoot,
    NoNetworkEmpty,
  },
  props: {
    page: Boolean,
    drawerConfig: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    secondaryCoupon: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      loading: false,
      isFirst: true,
      addOnInfo: {},
      preCouponInfos: [], // 记录上一次请求时券信息，主要用于对比进度
      originAddOnInfos: {}, // 原始信息，主要用于埋点
      curCouponCode: '', // 正在凑的券
      isShowRangeUpAnimation: false, // 是否展示过升档气泡
      isShowProgressUpAnimation: false, // 是否展示过进度提升气派
      otherAddCartNum: 0,
    }
  },
  computed: {
    ...mapState(['abtInfo', 'language', 'progressDataPromise', 'isFullScreen']),
    couponInfo() {
      return this.addOnInfo?.newPromotionPopupInfo // 凑单信息
    },
    addItemType() {
      return this.couponInfo?.addItemType
    },
    couponItem() { // 正在凑单的券
      return this.couponInfo?.couponInfos?.find(v => v.isCheck == 1)
    },
    // 是否全场优惠券活动
    isCouponAdd() {
      return ['ns_all_coupon_add', 'coupon_add'].includes(this.$attrs.saInfo.activity_from)
    }, 
    // 主券的 coupon code
    mainCouponCode() {
      return this.$attrs?.coupon
    },
    footerType() {
      if (this.isBusinessCartBottom) return 'business-cart'
      return 'normal'
    },
    anotherInfo() {
      const { goodsPrice, directTag, returnTag } = this.couponItem.recommendInfo
      const jsonRuleId = this.abtInfo?.addnewhotJson?.param || {}
      return {
        goodsPrice: (goodsPrice * 100).toFixed(0), // 凑单差额价格(美分)
        includeTspId: directTag,
        excludeTspId: returnTag,
        jsonRuleId,
      }
    },
    isMultiple() {
      // 0 - 单券, 1 - 多券-A方案, 2 - 多券B方案
      return [1, 2].includes(this.addOnInfo?.styleType)
    },
    isOriginMultiple() {
      return [1, 2].includes(this.originAddOnInfos?.styleType)
    },
    headerName() {
      if (this.isCouponAdd) return 'CouponAddHead'
      if (this.isMultiple) return 'MultipleHead'
      return 'NormalHead'
    },
    footerName() {
      if (this.isCouponAdd) return 'CouponAddFoot'
      return 'NormalFoot'
    },
    abtType() {
      const { cartadd_coupon_bottom_style } = this.abtInfo?.CartaddCouponBottomStyle?.param || {}
      return cartadd_coupon_bottom_style || 'A'
    },
    feedsType() {
      const { cartpop_feeds_additems } = this.abtInfo?.CartaddFeeds?.param || {}
      return cartpop_feeds_additems || 'off' // off | on_A | on_B
    },
    isBusinessCartBottom() {
      if (this.isCouponAdd) return false
      return ['A', 'B', 'C'].includes(this.abtType) && !this.config.isCloseBusinessCartEntry
    },
    addOnStatus() {
      return getSatisfiedValue(this.couponItem)
    },
    isFreeShipAndSingle() {
      return this.couponItem?.couponDiscountType == 0 && !this.isMultiple
    },
    fullScreenStyle() {
      return this.isFreeShipAndSingle ? {
        '--head-bg': `url(${this.couponInfo?.backgroundImage || ''})`,
      } : {
        '--head-bg': `url('/pwa_dist/images/add-on/coupon-addon-header-bg-686159f880.png')`,
      }
    },
    // 凑单弹窗背景样式
    couponStyle() {
      if(this.isFreeShipAndSingle) return { // 仅单券凑单的邮费券应用新样式
        '--header-background-image': `url(${this.couponInfo?.backgroundImage || ''})`,
        '--header-background-image-page': `url(${this.couponInfo?.backgroundImageWholePage || ''})`,
      }
      return null
    },
    // 非标购物车背景样式
    themeVar() {
      if(this.isFreeShipAndSingle) return { // 仅单券凑单的邮费券应用新样式
        '--main-background-image': 'url(https://img.ltwebstatic.com/images3_ccc/2024/10/17/10/1729170158fe76439810703bb2952ed97f8bf4ac9d.png)',
        '--delete-background-img': 'url(https://img.ltwebstatic.com/images3_ccc/2024/10/17/10/1729170158fe76439810703bb2952ed97f8bf4ac9d.png)'
      }
      return {}
    },
    // 进度条样式
    barStyle() {
      if(this.isFreeShipAndSingle) return { // 仅单券凑单的邮费券应用新样式
        FIRST_RANGE_BORDER: '0.5px solid #FFFFFF',
        FIRST_RANGE_BG: 'repeating-linear-gradient(115deg, rgba(67,160,113,0.9) 0%, rgba(67,160,113,0.9) 1%, #198055 1%, #198055 2%)',
        MULTIPLE_RANGE_BORDER: '0.5px solid #FFFFFF',
        MULTIPLE_RANGE_BG: 'repeating-linear-gradient(115deg, rgba(67,160,113,0.9) 0%, rgba(67,160,113,0.9) 1%, #198055 1%, #198055 2%)',
        bottomBg: '#EAEAEA',
        iconColor: '#198055',
      }
      return {}
    },
  },
  mounted() {
    this.fetchCouponData()
  },
  methods: {
    async fetchCouponData() {
      if (this.isFirst) { 
        this.curCouponCode = this.mainCouponCode
        this.loading = true
      }

      try {
        const res = await getCouponInfo({
          couponCode: this.mainCouponCode,
          curCouponCode: this.curCouponCode,
          subCouponCodes: this.secondaryCoupon?.join(','),
          sort: this.config?.couponIsSorted ? 0 : 1, 
          fixedOrder: this.couponInfo?.fixedOrder,
          activityFrom: this.$attrs.saInfo.activity_from,
        })

        if (res.code != 0) {
          EventFetchError.notify({
            code: res.code,
            msg: res.msg,
          })
        }
        
        if (this.isFirst) {
          this.originAddOnInfos = cloneDeep(res?.info) || {}
        }

        this.preCouponInfos = cloneDeep(this.addOnInfo?.newPromotionPopupInfo?.couponInfos)
        this.addOnInfo = res?.info || {}

        if (this.isCouponAdd) return // 全场优惠券活动不需要往下走了
        
        if (!this.isFirst) {
          this.progressDataPromise?.resolve?.({
            bubbleText: this.getBubbleText(),
          }) 
        }

      } finally {
        this.loading = false
        this.isFirst = false
      }
    },
    async handleUpdateCart() {
      this.handleOtherCartAddNum()
      this.fetchCouponData()
      this.$refs.BusinessCartFoot?.refetchCarts()
    },
    getBubbleText() {
      const { couponCode } = this.couponItem || {}
      const preCouponItem = getCouponItem(this.preCouponInfos, couponCode)
      
      if (!isProgressUp(this.couponItem, preCouponItem)) return '' // 判断是否凑单进度提升
      if (this.isShowRangeUpAnimation) return '' // 展示过升档气泡就不再弹气泡了

      const isUp = isRangeUp(this.couponItem, preCouponItem)
      if (isUp) {
        this.isShowRangeUpAnimation = true
        return this.couponInfo?.upgradingBubbleTip
      }

      if (this.isShowProgressUpAnimation) return ''
      this.isShowProgressUpAnimation = true
      return this.couponInfo?.bubbleTip
    },
    async onChangeCoupon(coupon) {
      this.curCouponCode = coupon?.couponCode
      EventListReset.notify({ reset: true, type: 'changeCoupon' })
      await this.fetchCouponData()
      EventProgressUpdate.notify() // 更新进度
      this.$toast(this.language.SHEIN_KEY_PWA_30085)
      EventExposeAddCart.notify()
    },
    onClickCoupon(data) {
      switch (data.type) {
        case 'add':
        case 'check':
          this.onChangeCoupon(data.coupon)
          daEventCenter.triggerNotice({
            daId: '1-8-4-23',
            extraData: {
              position: 'button',
              coupon: data.coupon,
            }
          })
          break
        default:
          console.error('type is invalid')
          break
      }
    },
    handleOtherCartAddNum() {
      if (!this.isOriginMultiple) return
      const originCouponItem = this.originAddOnInfos?.newPromotionPopupInfo?.couponInfos?.find(v => v.isCheck == 1) // 最初券
      if (originCouponItem?.couponCode !== this.couponItem?.couponCode) {
        this.otherAddCartNum += 1
      }

    },
    getSaExtraData() {
      const { couponCode } = this.couponItem || {}
      const originCouponItem = this.originAddOnInfos?.newPromotionPopupInfo?.couponInfos?.find(v => v.isCheck == 1) // 最初券
      const curOriginCouponItem = getCouponItem(this.couponInfo?.couponInfos, originCouponItem?.couponCode)
      const isOtherRangeUp = this.originAddOnInfos?.newPromotionPopupInfo?.couponInfos?.some(originItem => {
        const item = getCouponItem(this.couponInfo?.couponInfos, originItem.couponCode)
        return  originItem.couponCode !== originCouponItem?.couponCode && isRangeUp(item, originItem)
      })

      if (this.isOriginMultiple) {  
        return {
          promotion_code: this.couponInfo?.couponInfos?.map(v => v.couponCode)?.join(','),
          is_satisfied: getSatisfiedValue(this.couponItem),
          coupon_change: isRangeUp(curOriginCouponItem, originCouponItem) ? 1 : 0,
          other_coupon_change: isOtherRangeUp ? 1 : 0,
          other_add_cart_number: this.otherAddCartNum,
          is_multiple_coupons: 'multiple',
          is_satisfied_all: this.couponInfo?.couponInfos?.map(v => getSatisfiedValue(v))?.join(','),
        }
      } else {
        return {
          promotion_code: couponCode,
          is_satisfied: getSatisfiedValue(this.couponItem),
          coupon_change: isRangeUp(this.couponItem, originCouponItem) ? 1 : 0,
          other_coupon_change: '-',
          other_add_cart_number: '-',
          is_multiple_coupons: 'single',
          is_satisfied_all: '-',
        }
      }
    },
    async hanldeBusinessCartUpdated(info) {
      if (['updateQuantity', 'delete', 'modify', 'updateAttr'].includes(info?.action)) {
        EventSetProgressPromise.notify()
        this.fetchCouponData()
        EventUpdateBusinessCart.notify(info) // 通知外层调用方
      }
    },
    handleOnlineStatus(val) {
      this.isOnline = val
    },
    getBusinessCartFoot() {
      return this.$refs.BusinessCartFoot
    },
    viewCart(data) {
      EventViewCart.notify(data)
    },
  },
}
</script>

<style lang="less" scoped>
.type-coupon {
  height: 100%;
  .header-animate-enter-active,.header-animate-leave-active { /* stylelint-disable-line */
    transition: all .2s;
  }
  .header-animate-enter,
  .header-animate-leave-to { /* stylelint-disable-line */
    height: 0;
    opacity: .6;
  }
}
</style>
